html, body {
  font-family: sans-serif;
  font-size: 12px;
  margin: 0px;
  height: 100%;
}

button {
  margin-right: 10px;
}

#root, #graph {
  height: 100%;
  width: 100%;
}
